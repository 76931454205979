import React, { createContext, useState, useContext } from "react";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { type ThemeProviderProps } from "next-themes/dist/types";

import About from "../pages/landing/about/about";
// new
interface IAppContext {
  clickedContact: boolean;
  setClickedContact?: any;
  faqStatus: boolean[];
  setFaqStatus?: any;
  midData?: any;
  contentData?: any;
  filteredData?: any;
  setFilteredData?: any;
  darkMode?: any;
  setDarkMode?: any;
}

const defaultState = {
  clickedContact: false,
  faqStatus: [false, false, false, false],
};

export const AppContext = createContext<IAppContext>(defaultState);

const AppContextProvider = (props: any) => {
  const [filteredData, setFilteredData] = useState({});
  const [clickedContact, setClickedContact] = useState(
    defaultState.clickedContact
  );
  const [faqStatus, setFaqStatus] = useState(defaultState.faqStatus);
  const [midData, , toggleBg, handleToggle] = About();
  const [darkMode, setDarkMode] = useState("light");

  return (
    <AppContext.Provider
      value={{
        midData,
        clickedContact,
        setClickedContact,
        faqStatus,
        setFaqStatus,
        filteredData,
        setFilteredData,
        darkMode,
        setDarkMode,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export function ThemeProvider({ children, ...props }: ThemeProviderProps) {
  return <NextThemesProvider {...props}>{children}</NextThemesProvider>;
}

export default AppContextProvider;
export const useAppContext = () => useContext(AppContext);
