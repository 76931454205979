import PrivacyPolicy from "./PrivacyPolicy";

const Privacy = () => {
  return (
    <>
      <div className="m-8">
        <PrivacyPolicy />
      </div>
    </>
  );
};

export default Privacy;
