import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "../pages/landing";
import NewLanding from "../pages/newlanding/index";
import ErrorPage from "../pages/error/Error";
import Privacy from "../pages/privacyPolicy";
// import Testnet from "../pages/landing/testnet/Testnet";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Landing />} /> */}
        <Route path="/" element={<NewLanding />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        {/* <Route path="/" element={<Testnet />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
