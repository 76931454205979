import React from "react";
import pixpelogo from "../../../assets/New-landing-page/Group 1331 1.png";
import pixelLogoDark from "../../../assets/pixel.png";
import signal from "../../../assets/signals.png";
import { IoReorderThree } from "react-icons/io5";
import { AppContext, useAppContext } from "../../../context/AppContext";
import { useContext, useState } from "react";
// import HamBurger from "../../../assets/mobileNav/Hamburger-Menu.png";
// import HamBurgerCross from "../../../assets/mobileNav/Hamburger-Cross.png";
import CloseIconMenu from "../../../assets/mobileNav/icons8-close-48.png";
import lightCloseIconMenu from "../../../assets/mobileNav/icons8-close-49.png";
import IconMenu from "../../../assets/mobileNav/icons8-menu-94.png";
import lightIconMenu from "../../../assets/mobileNav/icons8-menu-50.png";

function Header() {
  const { darkMode, setDarkMode } = useContext(AppContext);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const context = useAppContext();
  const navItems = [
    { name: "About", href: "#aboutus" },
    { name: "Roadmap", href: "#roadmap" },
    { name: "Tokenomics", href: "#tokenomics" },
    { name: "Team", href: "#team" },
    { name: "Partners", href: "#partners" },
    { name: "Events", href: "#news" },
    { name: "FAQ", href: "#faq" },
  ];
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  return (
    <nav
      className={`${
        isNavOpen ? "fixed xl1:static z-30" : ""
      } flex items-center w-full justify-between flex-wrap bg-white p-6 border-b-2 border-solid border-gray-300`}
      style={{
        color: darkMode === "dark" ? "white" : "black",
        background: darkMode === "dark" ? "#1d2630" : "white",
      }}
    >
      <div className="flex w-full justify-between">
        <div className="flex items-center flex-shrink-0 text-white">
          <a href="/">
            <img
              src={darkMode === "light" ? pixpelogo : pixelLogoDark}
              alt="pixpel"
            />
          </a>
          {/* <button
            className="bg-gray-200 hover:bg-gray-300 ml-4 text-gray-800 font-semibold py-2 px-4 rounded-3xl text-base"
            onClick={() => {
              window.location.href = "https://dex.pixpel.io/swap-master/swap";
            }}
          >
            DEX
          </button> */}
        </div>
        {/* Mobile NAV */}

        <div className="rotate-180 items-center top-[10vh] z-10 p-2 bg-none flex lg:hidden">
          <button onClick={toggleNav}>
            <img
              src={
                isNavOpen
                  ? darkMode === "light"
                    ? lightCloseIconMenu
                    : CloseIconMenu
                  : darkMode === "light"
                  ? lightIconMenu
                  : IconMenu
              }
              className={`${
                darkMode === "light" ? "rounded-lg p-2 w-10" : "w-10"
              }`}
              alt="Open Menu"
            />
          </button>
        </div>
        <div
          style={{
            backgroundColor: `${darkMode === "light" ? "#fff" : "#1d2630"}`,
          }}
          id="list-mob-div"
          className={`lg:hidden !z-50 fixed h-[85vh] right-[0vw] top-[15%] w-[100%] flex justify-center ${
            isNavOpen ? "w-full dropdownContent" : "pullUpContent w-full hidden"
          }`}
        >
          <ul
            className={`py-6 ${
              darkMode === "light" ? "text-black" : "text-white"
            } w-100 text-center font-poppins text-18.081 font-bold mx-auto `}
          >
            <li className="p-2">
              <a
                onClick={() => {
                  // setIsNavOpen((prev) => !prev);
                  console.log("header is open");
                }}
                href="/"
              >
                Home
              </a>
            </li>
            <li className="p-2">
              <a
                onClick={() => {
                  setIsNavOpen((prev: any) => !prev);
                }}
                href="#aboutus"
              >
                About
              </a>
            </li>
            <li className="p-2">
              <a
                onClick={() => {
                  setIsNavOpen((prev: any) => !prev);
                }}
                href="#team"
              >
                Team
              </a>
            </li>
            <li className="p-2">
              <a
                onClick={() => {
                  setIsNavOpen((prev: any) => !prev);
                }}
                href="#tokenomics"
              >
                Tokenomics
              </a>
            </li>
            <li className="p-2">
              <a
                onClick={() => {
                  setIsNavOpen((prev: any) => !prev);
                }}
                href="#roadmap"
              >
                Roadmap
              </a>
            </li>
            <li className="p-2">
              <a
                onClick={() => {
                  setIsNavOpen((prev: any) => !prev);
                }}
                href="#partners"
              >
                Partners
              </a>
            </li>
            <li className="p-2">
              <a
                onClick={() => {
                  setIsNavOpen((prev: any) => !prev);
                }}
                href="#investors"
              >
                Investors
              </a>
            </li>
            <li className="p-2">
              <a
                onClick={() => {
                  setIsNavOpen((prev: any) => !prev);
                }}
                href="#faq"
              >
                FAQ
              </a>
            </li>
            {/* <li className="p-2">
              <button className="bg-[#AAFFDE] hover:bg-[#1FF19F] items-center flex justify-between text-gray-800 font-semibold py-2 px-4 rounded-[10px] text-base">
                <img src={signal} width={25} height={25} />
                AirDrop
              </button>
            </li> */}
            <li className="p-2">
              <button
                className={`${
                  darkMode === "light"
                    ? "bg-gray-200 hover:bg-gray-300 text-gray-700"
                    : "bg-[#717A8B] hover:bg-[#A1ABBC]"
                } text-white font-semibold py-2 px-4 rounded-3xl text-base ml-2`}
                onClick={() => {
                  window.location.href = "https://home.pixpel.io";
                }}
              >
                Sign In
              </button>
            </li>
          </ul>
        </div>
        {/* MOBILE NAV End */}
        <div className=" hidden justify-between lg:flex">
          <ul className="flex items-center m-auto text-center font-bold">
            {navItems.map((val: any, key: any) => (
              <a
                href={val.href}
                className={`mx-2 rounded-md p-1 ${
                  darkMode === "light"
                    ? "hover:bg-[#E3FFF4]"
                    : "hover:bg-[#92FFD4]"
                }`}
              >
                <li>{val.name}</li>
              </a>
            ))}
          </ul>
          <div className="w-full flex items-center justify-end sm:hidden">
            <IoReorderThree size={32} />
          </div>
          <div className="w-full flex items-center justify-end sm:flex">
            {/* <button className="bg-[#AAFFDE] hover:bg-[#1FF19F] items-center flex justify-center text-gray-800 font-semibold py-2 px-4 rounded-[10px]">
              <img src={signal} width={25} height={25} />
              AirDrop
            </button> */}
            <button
              className={`${
                darkMode === "light"
                  ? "bg-gray-200 hover:bg-gray-300 text-gray-700"
                  : "bg-[#717A8B] hover:bg-[#A1ABBC]"
              } text-white font-semibold whitespace-nowrap py-2 px-4 w-full max-w-max rounded-3xl text-base ml-2`}
              onClick={() => {
                window.location.href = "https://home.pixpel.io";
              }}
            >
              Sign In
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
