import { useContext } from "react";
import React from "react";
import RoadmapImg from "../../../assets/New-landing-page/Frame 2219.png";
import Perspective_lines from "../../../assets/New-landing-page/Perspective lines.png";
import Rectangle from "../../../assets/New-landing-page/Rectangle 1781.svg";
import RectangleDark from "../../../assets/Rectangle 1781.svg";
import Roadmap_title from "../../../assets/New-landing-page/Roadmap title.svg";
import Roadmap_title_dark from "../../../assets/Roadmap title.png";
import { AppContext, useAppContext } from "../../../context/AppContext";
import FrameImg from "../../../assets/Frame 2219.png";

function Roadmap() {
  const { darkMode, setDarkMode } = useContext(AppContext);
  const context = useAppContext();
  return (
    <div
    id="roadmap"
      className="px-8 pt-8"
      // id="roadmap"
      // style={{
      //   color: darkMode === "dark" ? "white" : "black",
      //   background: darkMode === "dark" ? "#1d2630" : "white",
      // }}
    >
      <div
        className="relative flex justify-center bg-white rounded-2xl"
        // style={{
        //   color: darkMode === "dark" ? "white" : "black",
        //   background: darkMode === "dark" ? "#1d2630" : "white",
        // }}
      >
        <div>
          <div className="flex items-center justify-center">
            <img
              src={darkMode === "light" ? Roadmap_title : Roadmap_title_dark}
              alt="Roadmap title"
              className="flex items-center justify-center z-20 mt-8 md:px-0 px-4"
            />
          </div>
          <img
            src={darkMode === "light" ? Rectangle : RectangleDark}
            alt="Rectangle"
            className="absolute inset-0 w-full h-full object-cover rounded-2xl"
          />
          <img
            src={Perspective_lines}
            alt="roadmap"
            className={
              "absolute inset-0 w-full h-full object-cover rounded-2xl"
            }
          />
          <img
            src={darkMode === "light" ? RoadmapImg : FrameImg}
            alt="Roadmap"
            className="relative z-10 py-28 px-4"
          />
        </div>
      </div>
    </div>
  );
}

export default Roadmap;
