import React from "react";
import "../../responsive.scss";
import "../../App.scss";
import Header from "../../components/New-landing-page/header/header";
import Footer from "../../components/New-landing-page/footer/footer";
import CryptoGame from "../../components/New-landing-page/cryptogame/cryptogame";
import Aboutus from "../../components/New-landing-page/aboutus/aboutus";
import Roadmap from "../../components/New-landing-page/roadmap/roadmap";
import Tokenomics from "../../components/New-landing-page/tokenomics/tokenomics";
import Partners from "../../components/New-landing-page/partners/partners";
import FaqList from "../../components/New-landing-page/faq/index";
import Pressrelease from "../../components/New-landing-page/pressrelease/pressrelease";
import Team from "../../components/New-landing-page/team";
import ImageLight from "../../components/New-landing-page/news/ImageLight";
import Event from "../../components/New-landing-page/news/index";
import { useAppContext } from "../../context/AppContext";
function newlanding() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { darkMode } = useAppContext();
  return (
    <main
      className={`flex-1 ${darkMode === "light" ? "bg-white" : "bg-[#1a2630]"}`}
    >
      <Header />
      <div className="flex justify-center items-center">
        <div className="container justify-center">
          <CryptoGame />
          <Aboutus />
          <Roadmap />
          <Tokenomics />
          <Team />
          <Partners />
          <ImageLight />
          <Event />
          <FaqList />
          <Pressrelease />
        </div>
      </div>
      <Footer />
    </main>
  );
}

export default newlanding;
