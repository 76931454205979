import { useContext } from "react";
import React from "react";
import bgTop from "../../../assets/New-landing-page/bg-mid-light.png";
import bgDark from "../../../assets/Ornament2.png";
import { AppContext, useAppContext } from "../../../context/AppContext";

type Props = {};

const ImageLight = (props: Props) => {
  const { darkMode, setDarkMode } = useContext(AppContext);
  const context = useAppContext();
  return (
    <div
      style={{
        color: darkMode === "dark" ? "white" : "black",
        background: darkMode === "dark" ? "#1d2630" : "white",
        paddingTop: "10px",
        paddingBottom: "10px",
      }}
    >
      <div className="w-full mt-10 px-8">
        <img
          src={darkMode === "light" ? bgTop : bgDark}
          alt="pixpel"
          className="min-h-[400px] sm:min-h-[200px]"
        />
      </div>
    </div>
  );
};

export default ImageLight;
