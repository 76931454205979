import React, { useState, useContext } from "react";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import Bg from "../../../assets/New-landing-page/news/purplebg.png";
import underline from "../../../assets/New-landing-page/underline.png";
import left from "../../../assets/New-landing-page/news/left-btn.png";
import leftDark from "../../../assets/Group 1568.png";
import rightDark from "../../../assets/Group 1567.png";
import right from "../../../assets/New-landing-page/news/right-btn.png";
import cover1 from "../../../assets/Rectangle.png";
import cover2 from "../../../assets/New-landing-page/news/cover2.png";
import cover3 from "../../../assets/Rectangle 1764.png";
import cover4 from "../../../assets/Rectangle 1765.png";
import previousLight from "../../../assets/Upcoming events (4).png";
import previousDark from "../../../assets/dark mode images/Upcoming events (7).png";
import upcomingLight from "../../../assets/Upcoming events (5).png";
import upcomingDark from "../../../assets/Upcoming events (3).png";
import { AppContext, useAppContext } from "../../../context/AppContext";

const YourComponent = () => {
  const { darkMode, setDarkMode } = useContext(AppContext);
  const context = useAppContext();
  const [totalSlides, setTotalSlides] = useState(2);
  const [currentSlide, setCurrentSlide] = useState(1);

  const [totalPrevSlides, setPrevTotalSlides] = useState(2);
  const [currentPrevSlide, setCurrentPrevSlide] = useState(1);

  const arr = [
    {
      id: 1,
      type: [
        {
          name: "Startup Bootcamp Accelerator Program",
          date: "13 - 20 May",
          text: "Physical week number 2",
          img: cover1,
        },
        {
          name: "Startup Bootcamp Accelerator Program",
          date: "13 - 20 May",
          text: "Physical week number 2",
          img: cover2,
        },
      ],
    },
    {
      id: 2,
      type: [
        {
          name: "WOW Summit Hong Kong 2024",
          date: "26 - 27 March",
          text: "MAJOR APAC WEB3 EVENT",
          img: cover3,
        },
        {
          name: "Startup Bootcamp Accelerator Program",
          date: "26 - 27 March",
          text: "MAJOR APAC WEB3 EVENT",
          img: cover4,
        },
      ],
    },
  ];

  const arr2 = [
    {
      id: 1,
      type: [
        {
          name: "Startup Bootcamp Accelerator Program",
          date: "13 - 20 May",
          text: "Physical week number 2",
          img: cover3,
        },
        {
          name: "Startup Bootcamp Accelerator Program",
          date: "13 - 20 May",
          text: "Physical week number 2",
          img: cover4,
        },
      ],
    },
    {
      id: 2,
      type: [
        {
          name: "WOW Summit Hong Kong 2024",
          date: "26 - 27 March",
          text: "MAJOR APAC WEB3 EVENT",
          img: cover3,
        },
        {
          name: "Startup Bootcamp Accelerator Program",
          date: "26 - 27 March",
          text: "MAJOR APAC WEB3 EVENT",
          img: cover4,
        },
      ],
    },
  ];
  const goToPrev = () => {
    if (currentSlide > 1) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const goToNext = () => {
    if (currentSlide < totalSlides) {
      setCurrentSlide(currentSlide + 1);
    }
  };
  const goToPrev2 = () => {
    if (currentPrevSlide > 1) {
      setCurrentPrevSlide(currentPrevSlide - 1);
    }
  };

  const goToNext2 = () => {
    if (currentPrevSlide < totalPrevSlides) {
      setCurrentPrevSlide(currentPrevSlide + 1);
    }
  };

  return (
    <div
      id="news"
      style={{
        color: darkMode === "dark" ? "white" : "black",
        background: darkMode === "dark" ? "#1d2630" : "white",
        paddingBottom: "40px",
      }}
    >
      <div
        className="text-[#333333] px-8 rounded-b-2xl mx-8"
        style={{
          color: darkMode === "dark" ? "white" : "black",
          background: darkMode === "dark" ? "#7F1DFB" : "#dabcf3",
          borderRadius: "40px",
        }}
      >
        {/* Previous Events */}
        <div className="flex flex-col md:flex-row mt-5 md:mt-0 md:justify-between">
          {" "}
          <div className="my-10 px-10">
            <img
              src={darkMode === "light" ? upcomingLight : upcomingDark}
              alt="ellipse"
              title="ellipse"
            />
            <p className="font-bold text-md my-2">
              Check out the upcoming events for our exciting project.
            </p>
            <div className="flex items-center my-2">
              <img
                src={darkMode === "light" ? left : leftDark}
                alt="left-btn"
                onClick={goToPrev2}
                style={{ cursor: "pointer" }}
              />
              <p className="px-2">
                {currentPrevSlide}/{totalPrevSlides}
              </p>
              <img
                src={darkMode === "light" ? right : rightDark}
                alt="right-btn"
                onClick={goToNext2}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div className="flex justify-center items-center">
            {arr2
              ?.filter((val) => val.id === currentPrevSlide)
              ?.map((val: any, key: any) => (
                <div className="flex" key={key}>
                  {val.type.map((item: any, key: any) => (
                    <div className="w-1/2 my-5 mx-1 bg-[rgb(38,38,38)]">
                      <img src={item.img} alt="slide1" className="w-full h-[280px]"/>
                      <div className="text-white my-2 mb-4 px-4">
                        <p className="text-sm">{item.date}</p>
                        <p className="text-bold text-[18px]">{item.name}</p>
                        <p className="text-[10px]">{item.text}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>

        {/* Upcoming Events */}
        <div className="flex flex-col md:flex-row md:mt-0 md:justify-between">
          {" "}
          <div className="my-10 px-10">
            <img
              src={darkMode === "light" ? previousLight : previousDark}
              alt="ellipse"
              title="ellipse"
            />
            <p className="font-bold text-md my-2">
              Check out the upcoming events for our exciting project.
            </p>
            <div className="flex items-center my-2">
              <img
                src={darkMode === "light" ? left : leftDark}
                alt="left-btn"
                onClick={goToPrev}
                style={{ cursor: "pointer" }}
              />
              <p className="px-2">
                {currentSlide}/{totalSlides}
              </p>
              <img
                src={darkMode === "light" ? right : rightDark}
                alt="right-btn"
                onClick={goToNext}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div className="flex justify-center items-center">
            {arr
              ?.filter((val) => val.id === currentSlide)
              ?.map((val: any, key: any) => (
                <div className="flex" key={key}>
                  {val.type.map((item: any, key: any) => (
                    <div className="w-1/2 my-5 mx-1 bg-[#262626]">
                      <img src={item.img} alt="slide1" className="w-full h-[280px]"/>
                      <div className="text-white my-2 mb-4 px-4">
                        <p className="text-sm">{item.date}</p>
                        <p className="text-bold text-[18px]">{item.name}</p>
                        <p className="text-[10px]">{item.text}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourComponent;
