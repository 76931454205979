import React, { Fragment, useState, useContext } from "react";
import pressImg from "../../../assets/Press Release.png";
import pressImgDark from "../../../assets/dark mode images/Upcoming events.png";
import yahooLogo from "../../../assets/Yahoo.png";
import yahooLogoDark from "../../../assets/dark mode images/Yahoo.png";
import ellipse from "../../../assets/Ellipse 75.png";
import ellipseDark from "../../../assets/dark mode images/Ellipse 76.png";
import europaLogo from "../../../assets/EuropaPress.png";
import europaLogoDark from "../../../assets/dark mode images/EuropaPress.png";
import tcuLogo from "../../../assets/TCU.png";
import tcuLogoDark from "../../../assets/dark mode images/TCU.png";
import { AppContext, useAppContext } from "../../../context/AppContext";

function Pressrelease() {
  const { darkMode, setDarkMode } = useContext(AppContext);
  const context = useAppContext();

  return (
    <Fragment>
      <div
        style={{
          color: darkMode === "light" ? "black" : "white",
          background: darkMode === "light" ? "white" : "#1d2630",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <div
          style={{
            color: darkMode === "light" ? "black" : "white",
            background: darkMode === "light" ? "white" : "#1d2630",
          }}
        >
          <div
            className="rounded-xl flex flex-col bg-[#EFF0F4] px-20 sm:py-20 m-8 py-8 items-center relative"
            style={{
              color: darkMode === "light" ? "black" : "white",
              background: darkMode === "light" ? "white" : "#717A8B",
            }}
          >
            <div className="mb-[90px]">
              <img
                src={darkMode === "light" ? pressImg : pressImgDark}
                alt="yahoo"
                title="yahoo"
                className="mx-auto pt-8 text-2xl"
              />
            </div>
            <div className="flex flex-col lg:flex-row lg:gap-24 gap-8 items-center mb-10 py-8">
              {/* <a
                href="https://finance.yahoo.com/news/pixpel-concordium-bring-insurance-policy-074600622.html"
                rel="yahoo"
              >
                <img
                  src={darkMode === "light" ? yahooLogo : yahooLogoDark}
                  alt="yahoo"
                  title="yahoo"
                />
              </a> */}
              {/* <img
                src={darkMode === "light" ? ellipse : ellipseDark}
                alt="ellipse"
                title="ellipse"
              /> */}
              <a
                href="https://www.europapress.es/comunicados/internacional-00907/noticia-comunicado-pixpel-and-concordium-bring-insurance-policy-to-web-30-gaming-20220913094734.html"
                rel="Europa"
              >
                <img
                  src={darkMode === "light" ? europaLogo : europaLogoDark}
                  alt="europa"
                  title="europa"
                />
              </a>
              <img
                src={darkMode === "light" ? ellipse : ellipseDark}
                alt="ellipse"
                title="ellipse"
              />
              <a
                href="https://www.thecryptoupdates.com/many-p2e-problems-one-solution-pixpel/"
                rel="TCU"
              >
                <img src={darkMode === "light" ? tcuLogo : tcuLogoDark} alt="tcu" title="tcu" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Pressrelease;
