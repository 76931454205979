import { useContext } from "react";
import React from "react";
// import Hero_Image from "../../../assets/New-landing-page/Hero Image.png";
import Hero_Image from "../../../assets/Hero Image (1).png";
import HeroDark from "../../../assets/Hero Image.png";
import HeroDark1 from "../../../assets/Hero Image (2).png";
import crypto from "../../../assets/crypto.png";
import { AppContext, useAppContext } from "../../../context/AppContext";

const CryptoGame = () => {
  const { darkMode, setDarkMode } = useContext(AppContext);
  const context = useAppContext();
  return (
    <div className="flex- justify-center items-center">
      <section
        className={`${
          darkMode === "light"
            ? "bg-gray-100  my-2 rounded-[40px]"
            : "bg-[#37404C]  my-2 rounded-[40px]"
        }  px-6 w-90 mx-8 flex justify-between md:px-12 py-16 relative`}
        // style={{
        //   color: darkMode === "dark" ? "white" : "black",
        //   background: darkMode === "dark" ? "#1d2630" : "white",
        // }}
      >
        <div className="container text-left relative z-10   flex flex-wrap flex-col max-w-[400px]">
          <h2
            className="text-2xl md:text-3xl font-semibold mb-4 text-[#333333]"
            style={{
              color: darkMode === "dark" ? "white" : "black",
            }}
          >
            Crypto Games <u>Safe</u> and Fun
          </h2>

          <p
            className="text-gray-800 mb-4 md:mb-8 font-normal text-base md:text-lg max-w-[380px]"
            style={{
              color: darkMode === "dark" ? "white" : "black",
            }}
          >
            Pixpel was created to provide all-around services for the Crypto games community, where
            game developers and players have a place to connect, invest, and play. If you want to
            know more about us, please check out our deck.
          </p>
          <p
            className="text-gray-800 mb-4 md:mb-8 font-normal text-base md:text-lg"
            style={{
              color: darkMode === "dark" ? "white" : "black",
            }}
          >
            If you want to know more about us, please check out our deck.
          </p>
          <div>
            <a href="https://docsend.com/view/q83qhhu6h66ckvgk">
              <button className="bg-[#98E5FF] hover:bg-[#42CEFE] border-[#42CEFE] border-2 text-black font-semibold py-2 px-6 rounded-lg">
                Deck
              </button>
            </a>
          </div>
        </div>
        <div className="flex justify-end">
          <img
            // src={Hero_Image}
            src={darkMode === "light" ? Hero_Image : HeroDark1}
            alt="Cryptogame"
            className=" w-full h-full max-w-[100vw] hidden lg:block xl:object-contain object-cover"
          />
        </div>
      </section>
    </div>
  );
};

export default CryptoGame;
