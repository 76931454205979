import React, { useContext, useState } from "react";
import TokenomicsImg from "../../../assets/New-landing-page/Tokenomics.png";
import { AppContext, useAppContext } from "../../../context/AppContext";
import tokenomiDark from "../../../assets/Tokenomics.png";

function Tokenomics() {
  const { darkMode, setDarkMode } = useContext(AppContext);
  const context = useAppContext();

  return (
    // <section
    //   className=" h-100vh py-16 px-8"
    //   id="tokenomics"
    //   style={{
    //     color: darkMode === "dark" ? "white" : "black",
    //     background: darkMode === "dark" ? "#1d2630" : "white",
    //     paddingBottom: "20px",
    //     paddingTop: "20px",
    //     marginTop: "-200px",
    //   }}
    //   >
    //   </section>
    <div
      id="tokenomics"
      className="items-center  mx-6 2xl:mx-8 md:px-0 px-3 py-8 justify-center flex "
    >
      <img
        src={darkMode === "light" ? TokenomicsImg : tokenomiDark}
        alt="Tokenomics"
        className="w-full"
      />
    </div>
  );
}

export default Tokenomics;
