import { useEffect, useState, useContext } from "react";
import { useAppContext, AppContext } from "../../../context/AppContext";
import { ToastContainer, toast } from "react-toastify";
import { FullscreenLoader } from "../../../components/loader";
import vector from "../../../assets/Vector 199.png";
import vector1 from "../../../assets/Vector 199 (1).png";
import { faqList } from "./faqlist";
// import "../../../App.scss";
import ContactNFT from "../../../assets/images/contact-image.png";
import BrightSeperator from "../../../assets/images/Line 309.png";
import FaqUp from "../../../assets/icons/faq_up.png";
import FaqDown from "../../../assets/icons/faq_down.png";
import BubbleImage from "../../../assets/images/Bubbles-FAQ.png";
import bubblesMob from "../../../assets/mobileNav/faq/Bubbles and planet and gradient.png";
import headingQuestionLight from "../../../assets/New-landing-page/Upcoming events.png";
import handleLight from "../../../assets/Have more questions_.png";
import handleDark from "../../../assets/Upcoming events (2).png";
import * as emailjs from "emailjs-com";
import AOS from "aos";
import { Collapse, Button } from "antd";
import { IoChevronDownSharp } from "react-icons/io5";
import { MdChevronRight } from "react-icons/md";
const { Panel } = Collapse;
const SERVICE_ID: string = String(process.env.REACT_APP_SERVICE_ID);
const TEMPLATE_ID: string = String(process.env.REACT_APP_TEMPLATE_ID);
const PUBLIC_KEY: string = String(process.env.REACT_APP_PUBLIC_KEY);

declare type FapProps = {
  question: string;
  answer: string;
  id: number;
  handleFaqClicked: any;
};

const customPanelStyle = {
  borderRadius: 10,
  marginBottom: 12,
  border: 0,
  overflow: "hidden",
  maxWidth: "1088px",
};

const FaqItem = (props: FapProps) => {
  const { darkMode, setDarkMode } = useContext(AppContext);
  const context = useAppContext();
  const [isOpen, setIsOpen] = useState(false);

  const toggleFaq = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <Collapse
        bordered={false}
        activeKey={isOpen ? "1" : ""}
        onChange={toggleFaq}
        expandIconPosition="right"
        expandIcon={() => (
          <>
            {isOpen ? (
              <IoChevronDownSharp className="text-gray-500 text-xl" />
            ) : (
              <MdChevronRight className="text-gray-500 text-xl" />
            )}
          </>
          //  <img
          //   src= {isOpen ? vector1 : vector1}
          //   alt={isOpen ? "open" : "closed"}
          // />
        )}
      >
        <Panel
          header={
            <span className="text-[20px] leading-normal font-semibold">{props.question}</span>
          }
          key="1"
          style={customPanelStyle}
        >
          <div
            id="FAQ-Toggle-Data"
            className="text-[#717A8B] bg-[#DFDFDF] font-medium text-2md  flex openitem-bg  p-5 rounded-xl w-full"
          >
            {props.answer}
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

function Faq() {
  const context = useAppContext();
  const { darkMode, setDarkMode } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    from_name: "",
    message: "",
  });
  console.log(darkMode, "chk");
  const handleFaqClicked = (id: number) => {
    let _faqStatus: boolean[] = [false, false, false, false];
    for (let i = 0; i < context.faqStatus.length; i++) {
      if (id === i) {
        _faqStatus[i] = !context.faqStatus[i];
      }
    }

    context.setFaqStatus(_faqStatus);
  };

  const ValidateEmail = (mail: any) => {
    let mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (mail.match(mailformat)) return true;
    else return false;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (values.message === "") {
      toast.error("Please fill the message.");
      return;
    }

    if (!ValidateEmail(values.from_name)) {
      toast.warning("Incorrect email.");
      return;
    }

    setLoading(true);

    emailjs.send(SERVICE_ID, TEMPLATE_ID, values, PUBLIC_KEY).then(
      function (response) {
        console.log("email send response >>>>>>", response.status, response.text);
        setLoading(false);
        toast.success("Successfully submitted.");
      },
      function (err) {
        console.log("email send error >>>>>>", err);
        setLoading(false);
        toast.error("Error connection");
      },
    );
  };

  const handleChange = (e: any) => {
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <div
        style={{
          color: darkMode === "dark" ? "#fffff" : "black",
          background: darkMode === "dark" ? "#1d2630" : "white",
        }}
      >
        <ToastContainer />
        {loading === true && <FullscreenLoader msg="Loading" />}
        <div className="" id="faq">
          {context.clickedContact ? (
            <div className="contact-bg min-h-screen w-full">
              <div className="show flex flex-col-reverse md:flex-row mx-auto w-full gap-8 py-28 px-4 md:px-8 lg:px-12 xl:px-24 2xl:px-36 3xl:px-48">
                <div className="w-full md:w-2/5">
                  <div className="flex flex-col gap-8 mx-auto my-auto">
                    <div className="text-white text-6xl font-semibold text-decoration-line: none;">
                      We´d love to hear from you
                    </div>
                    <div className="flex flex-col items-center justify-center rounded-lg bg-white h-9 px-2">
                      <input
                        placeholder="Email"
                        type="text"
                        onChange={(e) => handleChange(e)}
                        value={values.from_name}
                        name="from_name"
                      />
                    </div>
                    <div className="flex flex-col items-center justify-center rounded-lg bg-white h-36 px-2">
                      <textarea
                        placeholder="Your message"
                        onChange={(e) => handleChange(e)}
                        cols={100}
                        className="h-32"
                        name="message"
                        value={values.message}
                      ></textarea>
                    </div>
                    <div className="flex flex-col tiny:flex-row gap-4">
                      <button
                        className="w-full subscribe-btn text-white h-12 mx-auto"
                        onClick={(e) => handleSubmit(e)}
                      >
                        Send
                      </button>
                      <button
                        className="w-full subscribe-btn text-white h-12 mx-auto"
                        onClick={() => context.setClickedContact(false)}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className=" py-16 flex items-center w-full">
              <div className="relative flex w-full  px-8  py-4 items-center justify-between text-white">
                <div className="flex  md:flex-row  flex-col items-start gap-8 w-full mb-8 justify-between">
                  <img
                    src={darkMode === "light" ? handleLight : handleDark}
                    alt="handle question"
                    title="handlequestion"
                  />
                  <div
                    className="flex flex-col w-full lg:w-3/5"
                    // style={{
                    //   color: darkMode === "dark" ? "#fffff" : "black",
                    //   background: darkMode === "dark" ? "#1d2630" : "white",
                    // }}
                  >
                    {faqList.map((dt, idx) => {
                      return (
                        <FaqItem {...dt} key={idx} id={idx} handleFaqClicked={handleFaqClicked} />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Faq;
