import React from "react";
import Concordium from "../../../assets/New-landing-page/Concordium.png";
// import Ornament2 from "../../../assets/New-landing-page/Ornament2.svg";
import startUp from "../../../assets/New-landing-page/Capa_1.png";
import PartnersImg from "../../../assets/New-landing-page/partners (2).png";
import { AppContext, useAppContext } from "../../../context/AppContext";
import { useContext } from "react";

function Partners() {
  const { darkMode, setDarkMode } = useContext(AppContext);
  const context = useAppContext();
  return (
    <div
    // style={{
    //   color: darkMode === "dark" ? "white" : "black",
    //   background: darkMode === "dark" ? "#1d2630" : "white",
    //   paddingTop: "50px",
    //   paddingBottom: "50px",
    //   marginTop: "-30px",
    // }}
    >
      <div
        className="flex flex-col rounded-xl bg-[#1FF19F] m-8 py-8"
        id="partners"
      >
        <img src={PartnersImg} alt="Partners" className="mx-auto pt-8" />

        <div className="flex mx-auto p-8 flex-col md:flex-row md:gap-40 gap-10 py-10 md:py-20">
          <a
            href="https://www.concordium.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Concordium} alt="Concordium" />
          </a>
          <a
            href="https://www.startupbootcamp.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={startUp} alt="startUp" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Partners;
