/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from "react";
import pixpelogo from "../../../assets/New-landing-page/Group 1331 1.png";
import footerImage from "../../../assets/New-landing-page/Footer BG.png";
import footerImageDark from "../../../assets/New-landing-page/FooterBGdark.png";
import linkedIn from "../../../assets/New-landing-page/Screenshot 2024-04-05 at 6.30.06 p.m 2.png";
import twitter from "../../../assets/New-landing-page/Screenshot 2024-04-05 at 6.30.06 p.m 1.png";
import telegram from "../../../assets/New-landing-page/image 4.png";
import discord from "../../../assets/New-landing-page/image 3.png";
import { MdLightMode } from "react-icons/md";
import { IoIosMoon, IoIosArrowDown } from "react-icons/io";
import { Dropdown, Space } from "antd";
import { AppContext, useAppContext } from "../../../context/AppContext";
import discordDark from "../../../assets/elements (2).png";
import telegramDark from "../../../assets/Layer_1 (1).png";
import twitterDark from "../../../assets/elements (1).png";
import LinkidinDark from "../../../assets/Layer_1.png";
import pixelDark from "../../../assets/Layer_1 (2).png";

const Footer = () => {
  // const [theme, setTheme] = useState<"light" | "dark">("light");
  const { darkMode, setDarkMode } = useContext(AppContext);
  const context = useAppContext();

  const items: { key: string; label: React.ReactNode }[] = [
    {
      key: "1",
      label: (
        <a
          onClick={() => {
            // setTheme("light");
            setDarkMode("light");
          }}
        >
          Light
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          onClick={() => {
            // setTheme("dark");
            setDarkMode("dark");
          }}
        >
          Dark
        </a>
      ),
    },
  ];

  // const items: MenuProps["items"] = [
  //   {
  //     key: "1",
  //     label: (
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://www.antgroup.com"
  //       >
  //         Light
  //       </a>
  //     ),
  //   },
  //   {
  //     key: "2",
  //     label: (
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://www.antgroup.com"
  //       >
  //         Dark
  //       </a>
  //     ),
  //   },
  // ];

  return (
    <div
      style={{
        color: darkMode === "dark" ? "white" : "black",
        background: darkMode === "dark" ? "#1d2630" : "white",
      }}
    >
      <footer
        className="bg-transparent text-black py-8 px-4 bg-white relative z-0"
        style={{
          color: darkMode === "dark" ? "white" : "black",
          background: darkMode === "dark" ? "#1d2630" : "white",
          marginTop: "-40px",
        }}
      >
        <div className="bg-transparent mx-auto flex justify-between items-start px-10 ">
          <div className="absolute z-[-10] md:flex hidden">
            <img
              src={darkMode === "light" ? footerImage : footerImageDark}
              alt="pixpel"
              className="min-h-[500px] "
            />
          </div>
          <div className="pt-8 flex flex-wrap justify-between w-full">
            <div className="mt-4">
              <img
                src={darkMode === "light" ? pixpelogo : pixelDark}
                alt="pixpel"
              />
              <p className="text-sm mt-4 ml-[17px]">©PIXPEL 2024</p>
            </div>
            <nav className="flex flex-col gap-3">
              <a
                href="#services"
                className="hover:underline font-semibold text-xl mb-4 mt-4"
              >
                Services
              </a>
              <a href="#aboutus" className="hover:underline">
                About Us
              </a>
              <a href="#roadmap" className="hover:underline">
                Roadmap
              </a>
              <a href="#tokenomics" className="hover:underline">
                Tokenomics
              </a>
              <a href="#team" className="hover:underline">
                Our Team
              </a>
              <a href="#partners" className="hover:underline">
                Partners
              </a>
            </nav>
            <nav className="flex flex-col gap-3">
              <a
                href="#support"
                className="hover:underline font-semibold text-xl mb-4 mt-4"
              >
                Support
              </a>
              <a href="#faq" className="hover:underline">
                FAQ
              </a>
              <a href="#contact" className="hover:underline">
                Contact Us
              </a>
              {/* <a href="https://home.pixpel.io/" className="hover:underline">
                Join Us
              </a> */}
            </nav>
            <nav className="flex flex-col items-center gap-3">
              <a
                href="#support"
                className="hover:underline font-semibold text-xl mb-4 mt-4"
              >
                Join Us
              </a>
              <a
                href="https://twitter.com/PixpelPlatform"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={darkMode === "light" ? twitter : twitterDark}
                  alt="pixpel"
                  className="w-12 h-12"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/pixpelplatform/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={darkMode === "light" ? linkedIn : LinkidinDark}
                  alt="pixpel"
                  className="w-12 h-12"
                />
              </a>
              <a
                href="https://discord.gg/wgHYhj6DKX"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={darkMode === "light" ? discord : discordDark}
                  alt="pixpel"
                  className="w-12 h-12"
                />
              </a>
              <a
                href="https://t.me/pixpel"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={darkMode === "light" ? telegram : telegramDark}
                  alt="pixpel"
                  className="w-12 h-12"
                />
              </a>
            </nav>
          </div>
        </div>
        <div className="flex w-full justify-between px-8 pt-24">
          <div>
            <p className="font-medium text-lg flex md:hidden">Pixpel LDA</p>
          </div>
          <div>
            <Dropdown menu={{ items }}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                style={{
                  borderRadius: "8px",
                  border: "1px solid var(--Gray-5-dark-mode, #717A8B)",
                  width: "119.254px",
                  height: "40.625px",
                  flexShrink: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0 12px",
                }}
              >
                <Space>
                  {darkMode === "light" ? <MdLightMode /> : <IoIosMoon />}
                  {darkMode === "light" ? "light" : "dark"}

                  <IoIosArrowDown />
                </Space>
              </a>
            </Dropdown>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
