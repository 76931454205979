import React, { useContext } from "react";
import { newCoreTeam, marketingTeam, devTeam } from "./teamlist";
import { Link } from "react-router-dom";
import underline from "../../../assets/New-landing-page/underline.png";
import teamHead from "../../../assets/New-landing-page/TeamHead.png";
import { AppContext, useAppContext } from "../../../context/AppContext";
import teamLight from "../../../assets/Upcoming events (6).png";
import marketingLight from "../../../assets/Frame 2217.png";
import coreLight from "../../../assets/Frame 2216.png";
import devLight from "../../../assets/Frame 2218.png";
import teamDark from "../../../assets/dark mode images/Upcoming events (7).png";
import marketingDark from "../../../assets/dark mode images/Frame 2217 (1).png";
import coreDark from "../../../assets/dark mode images/Frame 2216 (1).png";
import devDark from "../../../assets/dark mode images/Frame 2218 (1).png";
type Props = {};

export default function Team(props: Props) {
  const { darkMode, setDarkMode } = useContext(AppContext);
  const context = useAppContext();
  return (
    <div
    // style={{
    //   color: darkMode === "dark" ? "white" : "black",
    //   background: darkMode === "dark" ? "#1d2630" : "white",
    //   paddingBottom: "20px",
    //   paddingTop: "20px",
    //   marginTop: "-120px",
    // }}
    >
      <div
        className={`rounded-2xl mx-8 ${
          darkMode === "dark" ? "bg-[#2ebd862c]" : "bg-[#2ebd862f]"
        }`}
        // id="team"
        // style={{
        //   color: darkMode === "dark" ? "white" : "black",
        //   background: darkMode === "dark" ? "#183538" : "white",
        // }}
      >
        <div id="team" className="flex justify-center py-20 items-center">
          <img src={darkMode === "light" ? teamLight : teamDark} alt="pixpel" />
        </div>
        {/* CoreTeam */}
        <div className="p-3">
          <img src={darkMode === "light" ? coreLight : coreDark} alt="pixpel" />
          <div className="grid py-20 gap-x-8 gap-y-8 lg:grid-cols-3 lg:px-40 md:grid-cols-2 sm:grid-cols-1">
            {newCoreTeam.map((val: any, key: any) => (
              <a href={val.linkedin} className="font-bold" key={key}>
                <div className="flex justify-center items-center">
                  <img
                    width={200}
                    className="items-center"
                    src={val.photo}
                    alt={val.name}
                  />
                </div>
                <p
                  className={`${
                    darkMode !== "light"
                      ? "!text-white text-center"
                      : " text-center"
                  }`}
                >
                  {val.name}
                </p>
                <p className="text-center text-[#2EBD85]">{val.job}</p>
              </a>
            ))}
          </div>
        </div>
        {/* MARKETTEAM */}
        <div className="p-3">
          <img
            src={darkMode === "light" ? marketingLight : marketingDark}
            alt="pixpel"
          />
          <div className="grid gap-x-6 gap-y-6 lg:grid-cols-4 py-20 lg:px-40 md:grid-cols-2 sm:grid-cols-1">
            {marketingTeam.map((val: any, key: any) => (
              <a href={val.linkedin} className="font-bold" key={key}>
                <div className="flex justify-center items-center">
                  <img
                    width={200}
                    className="items-center"
                    src={val.photo}
                    alt={val.name}
                  />
                </div>
                <p
                  className={`${
                    darkMode !== "light"
                      ? "!text-white text-center"
                      : " text-center"
                  }`}
                >
                  {val.name}
                </p>
                <p className="text-center text-[#2EBD85]">{val.job}</p>
              </a>
            ))}
          </div>
        </div>
        {/* DevTEAM */}
        <div className="p-3">
          <img src={darkMode === "light" ? devLight : devDark} alt="pixpel" />
          <div className="grid gap-x-8 gap-y-8 lg:grid-cols-3 py-20 lg:px-40 md:grid-cols-2 sm:grid-cols-1">
            {devTeam.map((val: any, key: any) => (
              <a href={val.linkedin} className="font-bold" key={key}>
                <div className="flex justify-center items-center">
                  <img
                    width={200}
                    className="items-center"
                    src={val.photo}
                    alt={val.name}
                  />
                </div>
                <p
                  className={`${
                    darkMode !== "light"
                      ? "!text-white text-center"
                      : " text-center"
                  }`}
                >
                  {val.name}
                </p>
                <p className="text-center text-[#2EBD85]">{val.job}</p>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
