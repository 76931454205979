import React from "react";
import dex from "../../../assets/New-landing-page/aboutUs/dex.png";
import cex from "../../../assets/New-landing-page/aboutUs/cex.png";
import launchpad from "../../../assets/New-landing-page/aboutUs/launchpad.png";
import launchpad2 from "../../../assets/New-landing-page/aboutUs/launchpad2.png";
import nftMarket from "../../../assets/New-landing-page/aboutUs/nftMarket.png";
import inventory from "../../../assets/New-landing-page/aboutUs/inventory.png";
import gameDashboard from "../../../assets/New-landing-page/aboutUs/gameDashboard.png";
import tokenRoom from "../../../assets/New-landing-page/aboutUs/tokenRoom.png";
import stalking from "../../../assets/New-landing-page/aboutUs/stalking.png";
import wallet from "../../../assets/New-landing-page/aboutUs/wallet.png";
import Nftfeatures from "../../../assets/New-landing-page/aboutUs/Frame 2237.png";
import P2pProfile from "../../../assets/New-landing-page/aboutUs/Frame 2241.png";
import tabDark from "../../../assets/Frame 2243.png";
import blank from "../../../assets/New-landing-page/aboutUs/BLANK_ICON.png";
import { AppContext, useAppContext } from "../../../context/AppContext";
import { useContext } from "react";
const Section = () => {
  const { darkMode, setDarkMode } = useContext(AppContext);
  const context = useAppContext();
  const tabs = [
    // { img: blank, style: "hidden" },
    { img: nftMarket, style: "" },
    { img: launchpad2, style: "" },
    { img: wallet, style: "" },
    { img: Nftfeatures, style: "" },
    { img: P2pProfile, style: "" },
    { img: dex, style: "" },
    { img: stalking, style: "" },
    { img: tokenRoom, style: "" },
    { img: launchpad, style: "" },
    { img: inventory, style: "" },
    { img: gameDashboard, style: "" },
    { img: cex, style: "" },
  ];

  return (
    // <section
    //   className="bg-white h-100vh py-16 px-8"
    //   id="aboutus"
    //   style={{
    //     color: darkMode === "dark" ? "white" : "black",
    //     background: darkMode === "dark" ? "#1d2630" : "white",
    //   }}
    //   >
    //   </section>
    <div id="aboutus" className="container my-4 px-4">
      <div className="flex flex-col flex-wrap border border-gray-300 rounded-[40px]  justify-between  overflow-y-hidden">
        <div className="flex justify-center items-center p-4">
          <div
            className={`text-lg text-center font-semibold border-transparent hover:border-gray-700`}
          >
            <h2
              className="text-3xl relative font-semibold mb-4 text-[#333333]"
              style={{
                color: darkMode === "dark" ? "white" : "black",
              }}
            >
              <u>About</u> Us
            </h2>
            <p
              className="text-[22px] font-normal"
              style={{
                color: darkMode === "dark" ? "white" : "black",
              }}
            >
              Providing everything you need to play and create
            </p>
          </div>
        </div>
        {/* <div className="flex flex-col">
          {tabs.map((tab, index) => (
            <div className="" key={index}>
              <img
                src={tab.img}
                className={`${tab.style} ${
                  (index + 1) % 2 === 0 ? "my-8" : ""
                }`}
                width={500}
              />
            </div>
          ))}
        </div> */}
        <div className="flex flex-wrap justify-center items-center p-4 gap-10 mb-10">
          {tabs.map((tab, index) => (
            <div className="" key={index}>
              <img src={tab.img} width={500} alt="" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section;
